import React from "react";
import { Link, Navigate} from "react-router-dom";
import Parse from "parse";
import { isAuthenticated } from "../Auth/AuthService.js";


const AuthModule = () => {
  
   // check if authenticated and redirect to playlists if already authenticated
   if(isAuthenticated(Parse.User.current())){
    return (
      <Navigate to="/" />
    )
  }
    
  return (
    <div>
      <Link to="/register" >
        <button>Register</button>
    </Link>
      <br />
      <br />
      <Link to="/login">
        <button>Login</button>
      </Link>
      
    </div>
  );

};
export default AuthModule