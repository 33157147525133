import Parse from "parse";


export const createPhone = (phone, zip) => {
    const Num = Parse.Object.extend("Phone_Alert");
    const num = new Num();
    num.set("phoneNumber", phone);
    num.set("zipCode", zip);
    num.save().then((result) => {
        return result;
    })
}