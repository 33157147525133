

export default function Houses(){


    return (
        // <p>whaddup</p>
        <div>
            <h1> Housing Options</h1>
            <br/><br/>
            <h2> Display living options by zip code:</h2>
            <p> (New feature to be added in the future!)</p>
        </div>
    )
}
