import Parse from "parse";

export const getAllJobs = (zipCode) => {
    const Jobs = Parse.Object.extend("Job_List");
    const query = new Parse.Query(Jobs);
    query.equalTo('ZipCode', zipCode);
    return query.find().then((results) => {
        console.log(results)
        return results;
    });
};

export const createJob = (title, zip, wage, comp, link, location) => {
    const Job = Parse.Object.extend("Job_List");
    const job = new Job();
    job.set("HourlyPay", wage);
    job.set("ZipCode", zip);
    job.set("Location", location);
    job.set("Title", title);
    job.set("Company", comp);
    job.set("Link", link);
    try {
    job.save().then((result) => {
        console.log("result: ", result)
        return result;
    })
  }
  catch(error) {
        console.log("error in createJob")
    }
}
