import SingleJob from "./SingleJob.js"


export default function ListJobs( {jobs} ) {
    if (!jobs || jobs.length == 0) {
        console.log("no jobs...");
        return <div id="noneAvail">No jobs for the entered zip code are currently available, but try another zip code or check back soon!</div>
    };

    return (
        <div className="availJobs">
            <ul>
                {
                  jobs.map(
                    (job) =>
                    <SingleJob job={job} />
                )
              }
            </ul>
        </div>
    )
}



//if zipQuery.equalTo( numZipCode, job.get("ZipCode"))
