import {useState, useEffect} from "react";
import {getAllJobs, createJob} from "../../Services/JobListService.js"
import ListJobs from "./ListJobs.js"
import { isAuthenticated } from "../Auth/AuthService";
import Parse from "parse";


export default function Jobs() {

    const [jobs, setJobs] = useState([]);
    const [jobsTitle, setTitle] = useState("");
    const [jobsWage, setWage] = useState("");
    const [jobsZip, setZip] = useState("");
    const [jobsComp, setComp] = useState("");
    const [jobsLink, setLink] = useState("");
    const [jobsZips, setJobsZips] = useState([]);
    const [location, setLocation] = useState("");


    // Function for checking if added jobs meet 30 wage criteria:
    /*
    function calcs30(flag, wage, zip) {
            console.log("in 30wage: ", zip)

            if (zip.length <= 4 || isAuthenticated(Parse.User.current()) === false) {
                wage = -1;
                return wage;
            }
            if (Parse.User.current().get("isEmployee") === true) {
                wage = -1;
                return;
            }
            try {
                console.log("hello")
                const zipClass = Parse.Object.extend("zipcode");
                const zipQuery = new Parse.Query(zipClass);
                zipQuery.equalTo('zip_code', zip);

                zipQuery.find()
                .then((zipResult) => {
        // The object was retrieved successfully.
                var county = JSON.parse( JSON.stringify((zipResult[0])) )["county"] + " County";
                console.log("County: ", county)
                if (county.indexOf("Saint") !== -1) {
                    county = "St" + "."+county.slice(5);
                }
                if (county === 'St Joseph County') {
                    county = county.slice(0,2) + '.' + county.slice(2)
                }
                console.log("County: ", county)
                var state = JSON.parse( JSON.stringify((zipResult[0])) )["state"];
                const countyClass = Parse.Object.extend("County");
                const aptQuery = new Parse.Query(countyClass);
                aptQuery.equalTo('countyname', county);
                aptQuery.equalTo('state_alpha', state);
                console.log("aprtQuery: ", aptQuery);
                //need way for second equalto condition since there are duplicate county names across states (equal to state alpha too)
                aptQuery.findAll()
                .then((aptInfo) => {
                    console.log("apt is: ", aptInfo)
                    // The object was retrieved successfully.
                    // document.write(JSON.stringify(aptInfo[0])) ;
                    try {
                        var aptData = JSON.parse( JSON.stringify(aptInfo[0])) ;
                        console.log(aptData);
                        var hoursWorked = 40
                        var marketCost=aptData["fmr_0"];
                        var monthlyWage = (marketCost/.3).toFixed(2);
                        var hourlyWage = (monthlyWage/(4*hoursWorked)).toFixed(2); //assuming four total 5-day weeks worked in a month.
                        console.log("hourlywage: ", hourlyWage)
                        console.log("attempted wage: ", wage, " calculated wage: ", hourlyWage)
                        let addedWage = false;
                        if (wage >= hourlyWage) {
                            addedWage= true;
                            if (flag === true) {
                                console.log("user: ", Parse.User.current().get("isEmployee"), " wage truth: ", addedWage);
                                if (Parse.User.current().get("isEmployee") === false && addedWage === true) {
                                    try {
                                        createJob(jobsTitle, jobsZip, jobsWage, jobsComp, jobsLink, location)
                                        alert("Successfull Creating Job")
                                    }
                                    catch(error) {
                                        console.log("error with making new job ", error);
                                        alert("error with making new job")
                                    }
                                }
                                else {
                                    console.log("not an employer", Parse.User.current().get("isEmployee"))
                                    alert("You are not an Employer")
                                }
                            }
                            else {
                                console.log("not logged in")
                            }
                        }
                        return 0;
                    }
                    catch(error) {
                        console.log(error);
                    }
                });
            })
        }

        catch(error) {
            console.log("in function 30wage: ", error)
        }
            return 0;
    }
    */
   function GetVal() {
        var zipCode = document.getElementById("zipcodeJobs").value;

        if (zipCode.search(/[^0-9]/) !== -1 || zipCode.length !== 5){
        alert('You must enter a valid, 5-digit US Zipcode!');
        return;
        }

        //Convert input from string to number
        console.log(zipCode);
        let jobZips = [];
        getAllJobs(zipCode).then((jobs) => {
            jobZips = jobs;
            setJobsZips(jobZips)
            console.log(jobZips);
        });
    }


    function dispForm() {
      document.getElementById('popup').style.display = "block";
      document.getElementById('popup').style.transform = "translate(0%, 0%) scale(1)";
      document.getElementById('popup').style.transition = "transform 0.8s, top 0.8s";
    }

    function hideForm() {
      document.getElementById('popup').style.diplay = "none";
    }

    const onClickHandler = (e) => {
       // e.preventDefault();
        console.log(jobsTitle);
        try {
            createJob(jobsTitle, jobsZip, jobsWage, jobsComp, jobsLink).then((job) => {
            setJobs(job);
            })
        }
        catch(error) {
            console.log("error with making new job ", error);
        }
        document.getElementById('popup').style.transform = "translate(0%, -200%) scale(0.1)";
        document.getElementById('popup').style.transition = "transform 0.6s";
    }

    //Version of onClickHandler function that calls calcs30 Function
    /*
    const onClickHandler = (e) => {
        e.preventDefault();
        console.log("jobTitle: ",jobsTitle);
        let flag = false;
        const registered = isAuthenticated(Parse.User.current())
        if (registered === true) {
            console.log("authenticated: ", isAuthenticated(Parse.User.current()))
            flag = true;
        }
        // console.log("flag: ", flag)
       calcs30( flag, jobsWage, jobsZip) // make sure the new wage is above the 30% wage
    }
*/



    const updateTitle = (e) => {
        const {value} = e.target;
        setTitle(value);
    }

    const updateWage = (e) => {
        const {value} = e.target;
        setWage(value);
    }

    const updateComp = (e) => {
        const {value} = e.target;
        setComp(value);
    }

    const updateZip = (e) => {
        const {value} = e.target;
        setZip(value);
    }
    const updateLink = (e) => {
      const {value} = e.target;
      setLink(value);
    }
    const updateLocation = (e) => {
        const {value} = e.target;
        setLocation(value);
    }

    useEffect(() => {
        getAllJobs().then((jobs) => {
            setJobs(jobs);
            console.log(jobs);
        });
    }, []);



    return (
        <div>
            <h1> Job Listings</h1>
            <br/><br/>
            <h2> Display jobs by zip code:</h2>
            <div className="jobFilter">
              <label htmlFor="zipcodeJobs"></label>
              <input type="text" id="zipcodeJobs" name="zipcodeJobs" placeholder="Enter zip code"/>
              <button id="filterJobs" onClick={GetVal}><span><i className="fa-solid fa-magnifying-glass-location"></i></span></button>
            </div>

            <br/><br/><br/>

            <h4 className="dispJobForm">Employers, add additional jobs that suppport the 30% wage mission:</h4>
            <button className="dispJobForm" onClick={dispForm}>Add a job</button>
            <div id="popup">
              <form>
                <h3 id="jobFormHeader"> Add a job</h3>
                <label htmlFor="jobTitle">Job Title: </label>
                <input type="text" id = "job-title" name="JobTitle" onInput={updateTitle}/>
                <br/>
                <br/>
                <label htmlFor="jobWage">Wage: </label>
                <input type="text" id = "job-wage" name = "JobWage" onInput={updateWage}/>
                <br/>
                <br/>
                <label htmlFor="jobZip">Zip Code: </label>
                <input type="text" id = "job-zip" name="JobZip" onInput={updateZip}/>
                <br/>
                <br/>
                <label htmlFor="jobLocation">Job Location: </label>
                <input type="text" id = "job-loca" name="JobLocation" onInput={updateLocation}/>
                <br/>
                <br/>
                <label htmlFor="jobComp">Company: </label>
                <input type="text" id = "job-company" name="JobComp" onInput={updateComp}/>
                <br/>
                <br/>
                <label htmlFor="jobLink">Job Link: </label>
                <input type="text" id = "job-link" name="JobLink" onInput={updateLink}/>
                <br/>
                <br/>
                <button className = "formBtn" onClick={onClickHandler}>Submit</button>
                <button className = "formBtn" onClick={hideForm}>Cancel</button>
              </form>
            </div>
            <h2> Current job postings in your area: </h2>
            <ListJobs jobs={jobsZips} />
            <br/><br/><br/>
        </div>
    )
}
