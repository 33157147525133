import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { loginUser } from "./AuthService";
import AuthForm from "./AuthForm";
import { isAuthenticated } from "./AuthService";
import Parse from "parse";

const AuthLogin = () => {
  
  const [currentUser, setCurrentUser] = useState({
    PhoneNumber: "",
    password: ""
  });

  

  const history = useNavigate();

  // flags in the state to watch for add/remove updates
  const [add, setAdd] = useState(false);

  // useEffect that run when changes are made to the state variable flags
  useEffect(() => {
    if (currentUser && add) {
      console.log("hello")
        loginUser(currentUser).then((userLoggedIn) => {
          if (userLoggedIn) {
            alert(
              `${userLoggedIn.get("username")}, you successfully logged in!`
            );
            // Redirect back to main page
            <Navigate to ="/"/>
          } else {
            // Redirect to same page
            history.push("/login");
          }
          
          setAdd(false);
        });
      }
  }, [currentUser, add, history]);

  const onChangeHandler = (e) => {
    e.preventDefault();
    console.log(e.target);
    const { name, value: newValue } = e.target;
    console.log(newValue);

    setCurrentUser({
      ...currentUser,
      [name]: newValue
    });

    console.log(currentUser);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log("submitted: ", e.target);
    setAdd(true);
  };

  // check if authenticated and redirect to playlists if already authenticated
  if(isAuthenticated(Parse.User.current())){
    return (
      <Navigate to="/" />
    )
  }

  return (
    <div>
      <h1>Login</h1>
      <AuthForm
        user={currentUser}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        login={true}
      />
    </div>
  );
};

export default AuthLogin;