import Parse from "parse";
import React, {useState} from 'react';
import {createPhone} from "../../Services/createPhoneService.js"
import './Styling.css';

//Parse.initialize("rBu4d0Kp1HDKh5RHIOXiZnhi0EW2NrbypsReMkgI", "oNwiHbI60rfn6OyupVDHosgOmRcdytpUpmPjMeem"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
//Parse.serverURL = "https://parseapi.back4app.com/";

const Page = () => {

  const [slide, setSlide] = useState(40);
  const [phoneNum, setphoneNum] = useState("");
  const [PhoneZip, setPhoneZip] = useState(0);

  const onChangeHandler = (e) => {
    const {value} = e.target;
    setSlide(value);
  };

  const updatePhone = (e) => {
    const {value} = e.target;
    setphoneNum(value);
}

const updatePhoneZip = (e) => {
  var zip = Number(e.target.value);
  console.log(typeof(zip));
  console.log("zip: ", zip)
  setPhoneZip(zip);
}

  const onClickPhone = (e) => {
  e.preventDefault();
   console.log("in onClickPhone: ", e.target);

   try {
     setphoneNum("");
     setPhoneZip(0);
     document.getElementById("phone-number").value = "";
     document.getElementById("phone-zip").value = null;
       createPhone(phoneNum, PhoneZip)
   }
   catch(error) {
       console.log("error with making new phone ", error);
   }
}

  function getVals(){
    var zipCode = document.getElementById("zipcode").value;

    if (zipCode.search(/[^0-9]/) !== -1 || zipCode.length !== 5){
      alert('You must enter a valid, 5-digit US Zipcode!');
    }

    const zipClass = Parse.Object.extend("zipcode");
    const zipQuery = new Parse.Query(zipClass);
    zipQuery.equalTo('zip_code', zipCode);
    zipQuery.find()
    .then((zipResult) => {
      // The object was retrieved successfully.
      var dataObj = JSON.parse( JSON.stringify((zipResult[0])) );
      var data = JSON.stringify((zipResult[0]));
      var county = JSON.parse( JSON.stringify((zipResult[0])) )["county"] + " County";
      console.log("County: ", county)
      if (county.indexOf("Saint") !== -1) {
        county = "St" + "."+county.slice(5);
      }
      else if (county.indexOf("St ") != -1) {
        county = "St" + "."+county.slice(2);
      }
      else if (county.indexOf("Sainte") != -1) {
        county = "St" + "."+county.slice(6);
      }

      console.log("County: ", county)
      var state = JSON.parse( JSON.stringify((zipResult[0])) )["state"];
      // document.write(county)
      // document.getElementById("result").innerHTML = "Your county is: "+county+'\n'+data + state;

      const countyClass = Parse.Object.extend("County");
      const aptQuery = new Parse.Query(countyClass);
      // aptQuery.findAll().then((results) =>
      //   {
      //     console.log("aprtQuery1: ", results[0])
      //     console.log("variable county: ", county);
      //     results.forEach( r => {
      //      //console.log("r: ", r.get('countyname'));
      //       if (r.get('countyname') === "St. Lucie County") {
      //         console.log("county: ", r.get("countyname"));
      //       }
      //     })
      //   });
      aptQuery.equalTo('countyname', county);
      aptQuery.equalTo('state_alpha', state);
      console.log("aprtQuery: ", aptQuery);
      //need way for second equalto condition since there are duplicate county names across states (equal to state alpha too)
      aptQuery.findAll()
      .then((aptInfo) => {
        console.log("apt is: ", aptInfo)
        // The object was retrieved successfully.
        // document.write(JSON.stringify(aptInfo[0])) ;
        try {
          var aptString = JSON.stringify(aptInfo[0]) ;
          var aptData = JSON.parse( JSON.stringify(aptInfo[0])) ;
          console.log(aptData)
          var hoursWorked = 40
          var marketCost;

      //    if (aptVal === "efficiency") {
            marketCost=aptData["fmr_0"];
      /*    } else if (aptVal ==="one-bed") {
            marketCost=aptData["fmr_1"]
          } else if (aptVal ==="two-bed") {
            marketCost=aptData["fmr_2"]
          } else if (aptVal ==="three-bed") {
            marketCost=aptData["fmr_3"]
          } else if (aptVal ==="four-bed") {
            marketCost=aptData["fmr_4"]
          };
*/
          var monthlyWage = (marketCost/.3).toFixed(2);
          var hourlyWage = (monthlyWage/(4*hoursWorked)).toFixed(2); //assuming four total 5-day weeks worked in a month.
          document.getElementById("resultWage").innerHTML = "$"+ hourlyWage
          document.getElementById("resultZip").innerHTML = county + ", " + state + ", "+ zipCode
        }
      catch(error) {
        console.log(error)
      }
      }, (error) => {
        // The object was not retrieved successfully.
        alert('retrieval failure');
      });

    }, (error) => {
      // The object was not retrieved successfully.
      alert('Please enter a valid, 5-digit US Zipcode!');
    });
  }

var slider = document.getElementById("hoursWorked");
var output = document.getElementById("hours");
try {
  output.innerHTML = slider.value;
  slider.oninput = function() {
    output.innerHTML = this.value;
  }
}
catch(error) {
  console.log("in output inner html: ", error);
  }


  return (
    <div>
    <script src="https://kit.fontawesome.com/26a90a23ee.js" crossorigin="anonymous"></script>
      <h1> The 30% Minimum Wage</h1>
      <div id="solutionQuest">
      <p> Working families thrive if they can spend 30% or less of their
          full-time income on housing.   In 1970, you could work any job 40 hours
          a week and have this be true.  Now 45% of jobs don't pay enough to
          cover housing on 30% of income.  Let's fix it. </p>
      <h4 id="quest"> What's the solution?</h4>
      </div>
      <p>The 30% Minimum Wage.  Set by county annually, it's what
          employers need to pay their workers to afford the median studio
          apartment in their county on 30% of their gross income.</p>

      <h3> Find the recommended wage for your zip code/county using the Wage Calculator below.</h3>
    <br/><br/>

      <div>
        <h1>30Wage Calculator</h1>
        <br/>
        <h5 id="description"><em>Simply enter your zip code below to get living-wage job listings in your area!</em></h5>
      </div>
      <div className="wrapper">
        <label htmlFor="zipcode"></label>
        <input type="text" id="zipcode" className="zipcode" placeholder="Enter your zip code"/>
        <br/>
        {/* <div class="parent">
      <div class="child"> <input type="range" min="20" max="100" value={slide} className="slider" id="hoursWorked" onChange={onChangeHandler}/> </div>
      <div class="child"> <p>Hours Worked per Week: <span id="hours"></span></p></div>
        </div> */}
      <button id="wageCalc" onClick={getVals}><span><i className="fa-solid fa-magnifying-glass-dollar"></i></span></button>
      <br/>
      <output id="resultWage" htmlFor="zipcode"></output><br/>
      <output id="resultZip" htmlFor="zipcode"></output>
      </div>
     <br/><br/><br/><br/><br/><br/>
     <p> <em>Note that we provide the recommended minimum wage for individuals
    living in an efficiency/studio aparment. The 30Wage calculator is
    unable to account for the specific living circumstances invovled with an
    individual's household. </em> </p>
      <br/>
      <br/>

    <h4>Here at 30Wage, we focus on connecting workers to jobs paying a 30% wage.</h4>

    <div className="moreInfo">
      <div className="column">
          <h3>Employers:</h3>
          <ul type="Employers">
              <li>Please pay the 30% Minimum Wage in each county in which you operate.</li>
              <li>Encourage other employers to do the same.</li>
              <li>Work to help enable more housing in your county to bring that median studio apartment cost down.</li>
          </ul>
      </div>
      <div className="column">
          <h3>Workers:</h3>
          <ul type="Employers">
              <li>Work hard to find employers who pay at least the 30% Minimum Wage.</li>
              <li>Check the Job Listings page to see available jobs in your region.</li>
          </ul>
      </div>
      <div className="column">
        <h3>Landlords:</h3>
        <ul type="Landlords">
            <li>Set your rents so no tenant has to pay more than 30% of income on housing.</li>
        </ul>
      </div>
      <div className="column">
        <h3>Policy Makers:</h3>
        <ul type="PolicyMakers">
            <li> Please establish policy that requires employers to pay the 30% Minimum Wage.</li>
        </ul>
      </div>
  </div>
<br/><br/><br/>

      <hr/>
      <h3>
        Enter your phone number and zipcode to be alerted to job postings in your area.
      </h3>
      <form>
        <label className="phoneNum" htmlFor="phoneNumber">Phone Number: </label>
          <input type="text" id = "phone-number" name="phoneNumber" onInput={updatePhone}/>
        <label className="phoneNum" htmlFor="PhoneZip">Phone Zip Code: </label>
          <input type="number" id = "phone-zip" name="PhoneZip" onInput={updatePhoneZip}/>
        <button id="savePhone" onClick={onClickPhone}>Add Phone</button>
      </form>
      <br/>
      <br/>
      </div>
  );
};

export default Page;
