import Parse from "parse";

// used in auth register component
export const createUser = (newUser) => {
  const user = new Parse.User();
  console.log("in createuser, isEmployee: ", newUser.isEmployee)
  user.set("username", newUser.username);
  user.set("password", newUser.password);
  user.set("PhoneNumber", newUser.PhoneNumber);
  user.set("isEmployee", newUser.isEmployee)

  return user
    .signUp()
    .then((newUserSaved) => {
      return newUserSaved;
    })
    .catch((error) => {
      alert(`Error: ${error.message}`);
    });
};

// used in auth login component
export const loginUser = (currentUser) => {
    const user = new Parse.User();
    user.set("username", currentUser.username);
    user.set("PhoneNumber", currentUser.PhoneNumber);
    user.set("password", currentUser.password);

    return user
        .logIn()
        .then((currentUserSaved) => {
            return currentUserSaved;
          })
          .catch((error) => {
            alert(`Error: ${error.message}`);
          });

}

export const isAuthenticated = (currentUser) => {

  if (currentUser === null) {
    return false;
  }
  if (currentUser.authenticated() === true) {
    return true;
  }
  else {
    return false;
  }

}
