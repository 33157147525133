import React from "react";

const AuthForm = ({ user, onChange, onSubmit, login }) => {
  // name field conditionally renders based if it is a login or register page
  return (
    <div>
      <form onSubmit={onSubmit} autoComplete="off">
        {
            <div className="form-group">
          <label>Name</label>
          <br />
          <input
            type="text"
            className="form-control"
            id="name-input"
            value={user.username}
            onChange={onChange}
            name="username"
            //required
          />
        </div>
        }{" "}

        <div className="form-group">
          <label>Phone Number</label>
          <br />
          <input
            type="text"
            className="form-control"
            id="phone-input"
            value={user.PhoneNumber}
            onChange={onChange}
            name="PhoneNumber"
            required
          />
        </div>{" "}
        <div className="form-group">
          <label>Password</label>
          <br />
          <input
            type="password"
            className="form-control"
            id="password-input"
            value={user.password}
            onChange={onChange}
            name="password"
            min="0"
            //required
          />
        </div>{" "}
        <div className="form-group">
          <label>Type true if Employee Otherwise type false</label>
          <br />
          <input
            type="text"
            className="form-control"
            id="employee-input"
            onChange={onChange}
            name="Employee"
            required
          />
          </div>
          <br/>
        <div className="form-group">
          <button type="submit" onSubmit={onSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthForm;
