

export default function SingleJob({job}) {

    return (
        <li key={job.get("Title")}>
            <p className="job-title">{job.get("Title")}</p>
            <p className="job-wage">Wage: {job.get("Wage")}</p>
            <p className="job-company">Company: {job.get("Company")}</p>
            <p className="job-loc">Location: {job.get("Location")}</p>
            <p className="job-link"><a href={job.get("Link")} target="_blank">{job.get("Link")}</a></p>
            <hr/>
        </li>
    )
}
