//import Calc from "./Home/Home";
import AuthModule from "./Auth/Auth";
import AuthRegister from "./Auth/AuthRegister";
import AuthLogin from "./Auth/AuthLogin";
import Navbar from "./Navbar/Navbar"
import Page from "./PageDesc/PageDesc"
import Job from "./job_listings/jobs.js"
import House from "./housing_listings/houses.js"



import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

export default function Components() {
  return (
    <Router>
      <Navbar /> 
      <Routes>
        <Route path="/Home" element={<Page/>} /> 
        <Route path="/auth" element={<AuthModule/>} />
        <Route path="/register" element={<AuthRegister/>} />
        <Route path="/login" element={<AuthLogin/>} />
        <Route path="/job" element={<Job/>} />
        <Route path="/house" element={<House/>} />
        <Route path = "/" element={<Navigate to="/Home" />} />
      </Routes>
    </Router>
  );
}