// navbar for navigating through different pages of application
import { Link } from "react-router-dom";
import "./Navbar.css";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav" >
          <li className="nav-item" >
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item" >
            <Link to="/auth">Auth</Link>
          </li>
          <li className="nav-item" >
            <Link to="/job">Jobs</Link>
          </li>
          <li className="nav-item" >
            <Link to="/house">Housing</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
