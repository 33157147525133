import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom"
import { createUser } from "./AuthService";
import AuthForm from "./AuthForm";
import { isAuthenticated } from "./AuthService";
import Parse from "parse";

const AuthRegister = () => {
  const [newUser, setNewUser] = useState({
    name: "",
    PhoneNumber: "",
    password: "",
    isEmployee: false
  });

  const navigate = useNavigate();

  // flags in the state to watch for add/remove updates
  const [add, setAdd] = useState(false);

  // useEffect that run when changes are made to the state variable flags
  useEffect(() => {
    if (newUser && add) {
      createUser(newUser).then((userCreated) => {
        if (userCreated) {
          alert(
            `${userCreated.get("username")}, you successfully registered!`
          );
          // Redirect back to main page
          navigate("/Home");
        } else {
          // Redirect to same page
          navigate("/register");
        }
        setAdd(false);
      });
    }
  }, [newUser, add, navigate]);



  const onChangeHandler = (e) => {
    e.preventDefault();
    console.log(e.target);
    const { name, value: newValue } = e.target;
    console.log("on change in auth register: ", newValue);
    if (newValue === "true" || newValue === "True") {
      console.log("in if statement")
      setNewUser({
        ...newUser,
        isEmployee:true
      })
    }
    else {
      setNewUser({
        ...newUser,
        [name]: newValue
      });
    }

    console.log("in on change, setNewUser: ", newUser)
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log("submitted: ", e.target);
    setAdd(true);
  };

  // check if authenticated and redirect to home if already authenticated
  if(isAuthenticated(Parse.User.current())){
    return (
      <Navigate to="/Home" />
    )
  }

  return (
    <div>
      <h1>Register</h1>
      <AuthForm
        user={newUser}
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        login={false}
      />
    </div>
  );
};

export default AuthRegister;
